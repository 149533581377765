
export const keyEntriesPerPage: string = "EntriesPerPage";
export const keyPageNo: string = "PageNoSession";

export const SINGLE_MATCH_TEXT: string = "Single Match Found"
// export const SINGLE_MATCH_RESULT_TEXT: string = ""
export const MULTIPLE_MATCH_TEXT: string = "Multiple Matches Found"
export const MULTIPLE_MATCH_RESULT_TEXT: string = "Select desired parcel"
export const NO_MATCH_FOUND_TEXT: string = "Valid Address - No Match Found"
export const NO_MATCH__FOUND_RESULT_TEXT: string = "No assessment record found for address or surrounding properties"
export const NO_COVERAGE_TEXT: string = "Valid Address - No Assessment Record Found"
export const NO_COVERAGE_RESULT_TEXT: string = "Addresses below are on street segment"
export const STREET_VALIDATION_ERROR_TEXT: string = "The address you have entered is not valid, please try again"
// export const STREET_VALIDATION_ERROR_RESULT_TEXT: string = "please try again"
export const EXCEPTION_MATCH_TEXT: string = "Error while processing the request"
export const EXCEPTION_MATCH_RESULT_TEXT: string = "Please try again"
export const NO_MATCH_FOUND: string = "No Match Found"
export const DEFAULT_EMPTY_TEXT: string = ""
export const INVALID_ADDRESS_SMARTY_ERROR: string = "Invalid Address - No Match Found";

export const INVALID_APN_TEXT: string = "Invalid Entry"
export const INVALID_APN_RESULT_TEXT: string = "Only One Wildcard Allowed"

export const search_status_subdivision: string = "subdivision_search";
export const search_status_radial: string = "radial_search";

// export const MailToCustomerCare: string = "CustomerCare@stewart.com";
export const MailToCustomerCare: string = "SPECTR_Support@stewart.com";
export const MailToSubject: string = "Request for access to SPECTR application";
export const MailToBody: string = "Please provide me with access to the SPECTR web application.";

export const Policy_Max_Error_Message = "The Policy Number / File Number cannot exceed 100 characters, please try again";
export const Policy_Required_Error_Message = "The Policy Number / File Number required, please try again";
export const Policy_Invalid_Error_Message = '~ ! ^ " < > | { } % are invalid characters, please try again';

export const AddressSearchErrorMessages = {
    All_Fields_Required: "Full Street Address, City & State or ZIP required, please try again",
    Street_Address_Required: "Full Street Address required, please try again",
    City_State_Zip_Required: "City & State or ZIP required, please try again",
    Invalid_Street_Address: "` ~ ! @ $ % ^ & * ( ) _ = + [ { ] } \\ | ; : ‘ “ < > ? are invalid characters, please try again",
    Invalid_Unit_Field: "` ~ ! @ $ % ^ & * ( ) _ = + [ { ] } \\ | ; : ‘ “ < > ? \"space\" are invalid characters, please try again",
    Invalid_City_Field: "` ~ ! @ # $ % ^ & * ( ) _ = + [ { ] } \\ | ; : ‘ “ < > ? , / are invalid characters, please try again",
    Invalid_State_Field: "` ~ ! @ # $ % ^ & * ( ) - _ = + [ { ] } \\ | ; : ‘ “ < > ? , . / are invalid characters, please try again",
    Invalid_Zip_Field: "` ~ ! @ # $ % ^ & * ( ) _ = + [ { ] } \\ | ; : ‘ “ < > ?  , . / \"space\" are invalid characters, please try again",
    Minimum_Zip_Error: "A minimum of 5 characters required in the ZIP field, please try again",
    Maximum_Zip_Error: "The ZIP field cannot exceed 10 characters, please try again",
    Invalid_City_Numeric_Error: "Numeric characters are not allowed, please try again"
}

export const APNSearchErrorMessages = {
    All_Fields_Required: "The State, County & APN fields required, please try again",
    County_APN_Required: "County and APN required, please try again",
    County_Required: "County required, please try again",
    APN_Required: "APN required, please try again",
    Invalid_APN: "` ~ ! @ $ % ^ = [ { ] } \\ | ‘ “ < > ? are invalid characters, please try again",
    Maximum_APN_Error: "The APN field cannot exceed 50 characters, please try again",
    Maximum_APN_Error_60_Character: "The APN field cannot exceed 60 characters, please try again",
    Double_Astrik_Error: "Invalid entry, only one wildcard allowed",
}

export const LegalSearchFormErrorMessages = {
    lotFromLength: "The Lot From field cannot exceed 50 characters, please try again",
    lotToLength: "The Lot To field cannot exceed 50 characters, please try again",
    blockLength: "The Block field cannot exceed 25 characters, please try again",
    subdivisionNameLength: "The Subdivision Name field cannot exceed 255 characters, please try again",
    phaseNoLength: "The Phase Number field cannot exceed 25 characters, please try again",
    buildingNoLength: "The Building field cannot exceed 25 characters, please try again",
    unitFromLength: "The Unit From field cannot exceed 50 characters, please try again",
    unitToLength: "The Unit To field cannot exceed 50 characters, please try again",
    condomiumLength: "The Condominium Name field cannot exceed 255 characters, please try again",
    volumeLength: "The Volume field cannot exceed 25 characters, please try again",
    bookLength: "The Book field cannot exceed 25 characters, please try again",
    pageLength: "The Page field cannot exceed 25 characters, please try again",
    sectionLength: "The Section field cannot exceed 50 characters, please try again",
    townshipLength: "The Township field cannot exceed 50 characters, please try again",
    rangeLength: "The Range field cannot exceed 50 characters, please try again",
    abstractNameLength: "The Abstract Name field cannot exceed 255 characters, please try again",
    abstractNoLength: "The Abstract Number field cannot exceed 15 characters, please try again",
    q1Length: "The Quarter 1 field cannot exceed 15 characters, please try again",
    q2ToLength: "The Quarter 2 field cannot exceed 15 characters, please try again",
    q3Length: "The Quarter 3 field cannot exceed 15 characters, please try again",
    NoFieldError: "State, County and one additional field required, please try again",
    CountyRequiredError: "County and one additional field required, please try again",
    NoLegalFields: "Minimum one additional field required, please try again",
    LotFromGreaterValue: "The Lot From field must be less than or equal to the Lot To field, please try again",
    UnitFromGreaterValue: "The Unit From field must be less than or equal to the Unit To field, please try again",
    StartWithSpecialCharacter: "Minimum one alphanumeric character required",
}

export const AssessmentSearchFormErrorMessages = {
    subDivisionLength: "The Subdivision field cannot exceed 40 characters, please try again",
    LotFromLength: "The Lot field cannot exceed 7 characters, please try again",
    LotToLength: "The Lot field cannot exceed 7 characters, please try again",
    BlockLength: "The Block field cannot exceed 7 characters, please try again",
    DistrictLength: "The District field cannot exceed 12 characters, please try again",
    UnitLength: "The Unit field cannot exceed 6 characters, please try again",
    PhaseNumberLength: "The Phase Number field cannot exceed 7 characters, please try again",
    TractNumberLength: "The Tract Number field cannot exceed 10 characters, please try again",
    MinimumFieldsError: "Minimum one additional field required, please try again",
    NoFieldError: "State, County and one additional field required, please try again",
    CountyRequiredError: "County and one additional field required, please try again",
    LotFromGreaterValue: "The Lot From field must be less than or equal to the Lot To field, please try again",
    StartWithSpecialCharacter: "Minimum one alphanumeric character required",
}

export const OwnerNameSearchErrorMessages = {
    All_Fields_Required: "State, County, and Owner Name fields required, please try again",
    County_OwnerName_Required: "County and Owner Name required, please try again",
    County_Required: "County required, please try again",
    OwnerName_Required: "Owner Name required, please try again",
    // Invalid_APN: "` ~ ! @ $ % ^ = [ { ] } \\ | ‘ “ < > ? are invalid characters, please try again",
    Maximum_APN_Error: "The Owner Name field cannot exceed 166 characters, please try again",
    // Maximum_APN_Error_60_Character: "The APN field cannot exceed 60 characters, please try again",
    // Double_Astrik_Error: "Invalid entry, only one wildcard allowed",
}

export const ParcelDetail_Log_Event: string = "ParcelDetail";
export const DocumentView_Log_Event: string = "PolicyImage";

export const PolicySearch_Loggin_Event = {
    Policy_Search_Event: "PolicySearch",
    Policy_Search_Document_Event: "PolicySearch_DocumentView",
    Policy_Search_Parcel_Event: "PolicySearch_ParcelView"
}

export const LoggingEvent = {
    ParcelView: "_ParcelView",
    DocumentView: "_DocumentView",
}

export const PolicyLegalSearch_Logging_Event = {
    Legal_Search_Event: "PolicyLegalSearch",
    Legal_Search_Document_Event: "PolicyLegal_DocumentView",
    Legal_Search_Parcel_Event: "PolicyLegal_ParcelView"
}

export const AddressSearch_Logging_Event = {
    Search_Event: "AssessmentAddressSearch",
}

export const PolicyAddressSearch_Logging_Event = {
    Search_Event: "PolicyAddressSearch",
}

export const PolicyAPNSearch_Logging_Event = {
    Search_Event: "PolicyAPNSearch",
}

export const APNSearch_Logging_Event = {
    Search_Event: "AssessmentAPNSearch",
}

export const AssessmentLegalSearch_Logging_Event = {
    Assessment_Search_Event: "AssessmentLegalSearch",
    Assessment_Search_Document_Event: "AssessmentLegalSearch_DocumentView",
    Assessment_Search_Parcel_Event: "AssessmentLegalSearch_ParcelView"
}

export const Direct_Starter_Type_Log: string = "Direct";
export const Reference_Starter_Type_Log: string = "Reference";
export const Starter__Document_Type_Log: string = "PolicyImage";

export const DocumentSizeLimit: number = 1572864;