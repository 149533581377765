import logAPI from "../../common/Api/logAPI";
import { axiosSecuredInstance } from "../../configuration/axiosConfig";
import { AssessmentSearchRequest } from "../../entities/ApiModel/AssessmentSearchRequest";
import { OwnerNameSearchRequest } from "../../entities/ApiModel/OwnerNameSearchRequest";
import { LogAPISearchResult } from "../../utils/common/commonUtils";
import { AssessmentLegalSearch_Logging_Event, PolicyLegalSearch_Logging_Event } from "../../utils/constants/constants";

export const fetchByAssessmentOwnerSearch = async (request: OwnerNameSearchRequest) => {

    let inputRequest = {
        state: request.state,
        county: request.county,
        owner_name: request.ownerName,
        offset_value: request.offset_value,
        fetchnext_value: request.fetchnext_value
    };
    let config = {
        method: 'post',
        url: 'api/v1/assessment/owner/search',
        data: JSON.stringify(inputRequest)
    };
    const response: any = (await axiosSecuredInstance.request(config)).data.data;
    // logAPI({
    //     Event: AssessmentLegalSearch_Logging_Event.Assessment_Search_Event,
    //     UserInput: request,
    //     SearchResult: LogAPISearchResult(response?.match_code),
    //     State: request.state,
    //     Fips: request.fips,
    //     County: request.county
    // });
    return response;
};